import { valueToDate, writeToFile } from './utils';

import { TableState } from '@/hooks/useTableState';
import { tableStateToQuery } from '@/utils/tableStateToQuery';
import { fetchAllData } from '@/api/fetchAllData';
import { GetContentsResponse } from '@/api/types/contents';
import { translateContentAttribute } from '@/constants/contents';

export const mapContentsToFlatArray = (data: GetContentsResponse['data']) =>
    data.map((content) => {
        const size = content.attributes.content_data.data?.attributes.size;

        return Object.entries(content.attributes).reduce<Record<string, string | Date | number | null>>(
            (acc, [a, value]) => {
                const attribute = a as keyof typeof content.attributes;
                const translatedAttribute = translateContentAttribute(attribute);

                if (!(translatedAttribute in acc)) {
                    return acc;
                }

                if (typeof value === 'string') {
                    acc[translatedAttribute] =
                        attribute === 'createdAt' || attribute === 'updatedAt' ? valueToDate(value) : value || null;
                } else if (!value?.data) {
                    acc[translatedAttribute] = null;
                } else if (Array.isArray(value.data)) {
                    acc[translatedAttribute] =
                        value.data.map((relation) => relation.attributes.name).join(', ') || null;
                } else {
                    const name =
                        'name' in value.data.attributes
                            ? value.data.attributes.name
                            : value.data.attributes.subdomain_name;
                    acc[translatedAttribute] = name || null;
                }

                return acc;
            },
            {
                id: content.id,
                [translateContentAttribute('domain_id')]: null,
                [translateContentAttribute('subdomain_id')]: null,
                [translateContentAttribute('disciplines_id')]: null,
                [translateContentAttribute('status')]: null,
                [translateContentAttribute('description')]: null,
                [translateContentAttribute('content_data')]: null,
                'Размер файла (Mb)': size ? Number((size / 1024).toFixed(3)) : null,
                [translateContentAttribute('createdAt')]: null,
                [translateContentAttribute('updatedAt')]: null,
            },
        );
    });

export const downloadContent = (table: TableState, onSuccess: () => void, onError: (text?: string) => void) => {
    const tableQuery = tableStateToQuery('contents', table);
    const query = {
        ...tableQuery,
        populate: '*',
    };

    fetchAllData<GetContentsResponse>(query, '/ssu-contents')
        .then((rawData) => {
            const data = mapContentsToFlatArray(rawData);

            if (!data.length) {
                onError('Нет данных');

                return;
            }

            writeToFile(data, 'contents');
            onSuccess();
        })
        .catch(() => {
            onError();
        });
};
