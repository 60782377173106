import { TableRow } from '../Tables/StatisticsTable';

import { mapContentsToFlatArray } from './downloadContent';
import { mapQuestionsToFlatArray } from './downloadQuestions';
import { writeToFile } from './utils';

import { translateQuestionAttribute } from '@/constants/questions';
import { GetContentsResponse } from '@/api/types/contents';
import { GetQuestionsResponse } from '@/api/types/questions';
import { DownloadData } from '@/hooks/useStatisticsPage';
import { StatisticState } from '@/hooks/useStatisticState';
import { getFilter } from '@/utils/tableStateToQuery';
import { fetchAllData } from '@/api/fetchAllData';

const tableRowToFlat = (row: TableRow) => {
    const common = {
        [translateQuestionAttribute('domain_id')]: row.domain_id || null,
        [translateQuestionAttribute('subdomain_id')]: row.subdomain_id || null,
        [translateQuestionAttribute('disciplines_id')]: row.disciplines_id || null,
    };

    const question = {
        'Кол-во вопросов (всего)': row.total,
        Открытый: row.open,
        Закрытый: row.closed,
        Генеративный: row.generative,
    };

    const content = {
        'Кол-во контента (всего)': row.contentTotal,
        'Размер контента (Mb)': row.contentVolume,
    };

    const isQuestion = row.open > 0 || row.closed > 0 || row.generative > 0;

    return isQuestion ? { ...common, ...question } : { ...common, ...content };
};

type FlatRow = ReturnType<typeof tableRowToFlat>;

const statisticsTableDataToFlatJson = (data: TableRow[]): FlatRow[] =>
    data.reduce<FlatRow[]>((acc, cur) => {
        acc.push(tableRowToFlat(cur));

        if (cur.subRows) {
            acc.push(...statisticsTableDataToFlatJson(cur.subRows));
        }

        return acc;
    }, []);

const mapStatisticsToFlatArray = (data: Exclude<DownloadData, TableRow[]>) => {
    return data.questionsList.data.map((question, i) => {
        const content = data.contentList.data[i];
        const contentVolume = data.contentVolumeList.data[i];

        return {
            Пользователь: question.name,
            'Контент — количество ': content?.value,
            'Контент — объём': contentVolume?.value,
            'Вопросы — количество': question.value,
        };
    });
};

const getAllQuestions = async (domains: StatisticState['selectedDomainIds']) => {
    const query = {
        populate: '*',
        filters: { $and: [getFilter('domain_id', domains)] },
    };

    return fetchAllData<GetQuestionsResponse>(query, '/ssu-questions');
};

const getAllContent = async (domains: StatisticState['selectedDomainIds']) => {
    const query = {
        populate: '*',
        filters: { $and: [getFilter('domain_id', domains)] },
    };

    return fetchAllData<GetContentsResponse>(query, '/ssu-contents');
};

export const downloadStatistics = async (
    statisticState: StatisticState,
    onSuccess: () => void,
    onError: (text?: string) => void,
    downloadData?: DownloadData,
) => {
    if (!downloadData) {
        onError('Нет данных');

        return;
    }

    if (statisticState.activeTabIndex === 1) {
        writeToFile(mapStatisticsToFlatArray(downloadData as Exclude<DownloadData, TableRow[]>), 'users');
        onSuccess();

        return;
    }

    const isQuestions = statisticState.parameter === 'questions';

    const loadPromise = isQuestions
        ? getAllQuestions(statisticState.selectedDomainIds)
        : getAllContent(statisticState.selectedDomainIds);

    loadPromise
        .then((rawData) => {
            const mappedData = isQuestions
                ? mapQuestionsToFlatArray(rawData as GetQuestionsResponse['data'])
                : mapContentsToFlatArray(rawData as GetContentsResponse['data']);
            const statistics = statisticsTableDataToFlatJson(downloadData as TableRow[]);

            if (!mappedData.length) {
                onError('Нет данных');

                return;
            }

            writeToFile(mappedData, isQuestions ? 'questions' : 'contents', statistics);
            onSuccess();
        })
        .catch(() => {
            onError();
        });
};
