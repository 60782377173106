import { createGlobalStyle } from 'styled-components';
import { standard } from '@salutejs/plasma-typo';
import { light } from '@salutejs/plasma-tokens-web/themes';
import { text, background, fonts } from '@salutejs/plasma-tokens-web';
import { b2b } from '@salutejs/plasma-tokens-b2b/typo';

const DocumentStyle = createGlobalStyle`
    & html {
        ${b2b[':root']};
        font-family: ${fonts.Medium};
        color: ${text};
    }

    & body {
        position: relative;
        margin: 0;
        box-sizing: border-box;
        background-color: ${background};
    }
`;

const ThemeStyle = createGlobalStyle(light);
const TypoStyle = createGlobalStyle(standard);

export const GlobalStyle = () => (
    <>
        <DocumentStyle />
        <ThemeStyle />
        <TypoStyle />
    </>
);
