import { FC, PropsWithChildren, createContext, useCallback, useContext, useMemo, useState } from 'react';

type TokenContext = {
    token: string | null;
    handleTokenResponse: (res: Response) => void;
};

type TokenResponse = {
    access_token: string;
    expires_at: number;
};

const GigaChatTokenContext = createContext<TokenContext>({
    token: null,
    handleTokenResponse: () => {},
});

export const GigaChatTokenProvider: FC<PropsWithChildren> = ({ children }) => {
    const [token, setToken] = useState<string | null>(null);

    const handleTokenResponse = useCallback(
        (response: Response) =>
            response.json().then((json: TokenResponse) => {
                if (!('access_token' in json) || !('expires_at' in json)) {
                    throw new Error('No access token in response');
                }

                setToken(json.access_token);
                const diff = json.expires_at - new Date().getTime();

                setTimeout(() => {
                    setToken(null);
                }, diff);
            }),
        [],
    );

    const value = useMemo(() => ({ token, handleTokenResponse }), [token, handleTokenResponse]);

    return <GigaChatTokenContext.Provider value={value}>{children}</GigaChatTokenContext.Provider>;
};

export const useGigaTokenContext = () => {
    return useContext(GigaChatTokenContext);
};
