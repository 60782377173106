import React from 'react';
import styled from 'styled-components';
import { BodyM, Radiobox, RadioGroup } from '@salutejs/plasma-web';

import { DatePicker } from '../DatePicker/DatePicker';

import { PARAMETERS, ParameterT, AGGREGATIONS, AggregationT } from './constants';

import { PropsType } from '@/types/PropsType';

const StyledContainer = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
`;

const StyledRadioGroup = styled(RadioGroup)`
    display: flex;
    align-items: center;
    gap: 16px;
    flex-wrap: wrap;
`;

const StyledDatePicker = styled(DatePicker)`
    min-width: 312px;
`;

const datePickerSizes = {
    totalHeight: 32,
    paddingVertical: 4,
    paddingHorizontal: 8,
};

type DatePickerChangeHandler = PropsType<typeof DatePicker>['onChange'];

type RadioGroupProps = {
    title: string;
    radios: { value: string; label: string }[];
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    initialValue?: string;
    initialPeriod?: Parameters<DatePickerChangeHandler>[0];
    onDatePickerChange?: DatePickerChangeHandler;
};

export const RadioGroupComponent: React.FC<RadioGroupProps> = ({
    title,
    radios,
    initialValue,
    initialPeriod,
    onDatePickerChange,
    onChange,
}) => {
    const id = `radiogroup-statistic-radio-title-${title}`;
    const name = `statistic-radio-${title}`;

    return (
        <StyledRadioGroup aria-labelledby={id}>
            <BodyM id={id} bold>
                {title}:
            </BodyM>
            {radios.map(({ value, label }, i) => (
                <Radiobox
                    key={value}
                    name={name}
                    value={value}
                    label={label}
                    defaultChecked={initialValue ? value === initialValue : i === 0}
                    onChange={onChange}
                />
            ))}
            {onDatePickerChange ? (
                <StyledDatePicker
                    defaultValueText="Выберите период"
                    onChange={onDatePickerChange}
                    sizes={datePickerSizes}
                    initialDates={initialPeriod}
                />
            ) : null}
        </StyledRadioGroup>
    );
};

type StatisticRadioProps = {
    onParameterChange: (parameter: ParameterT) => void;
    onAggregationChange: (aggregation: AggregationT) => void;
    onPeriodChange: (period: Parameters<DatePickerChangeHandler>[0]) => void;
    initialParameter?: ParameterT;
    initialAggregation?: AggregationT;
    initialPeriod?: Parameters<DatePickerChangeHandler>[0];
};

export const StatisticRadio: React.FC<StatisticRadioProps> = ({
    initialParameter,
    initialPeriod,
    initialAggregation,
    onParameterChange,
    onAggregationChange,
    onPeriodChange,
}) => {
    return (
        <StyledContainer>
            <RadioGroupComponent
                title="Показатели"
                initialValue={initialParameter}
                onChange={(e) => onParameterChange(e.target.value as ParameterT)}
                radios={PARAMETERS}
            />
            <RadioGroupComponent
                title="Период"
                initialPeriod={initialPeriod}
                initialValue={initialAggregation}
                onChange={(e) => onAggregationChange(e.target.value as AggregationT)}
                radios={AGGREGATIONS}
                onDatePickerChange={onPeriodChange}
            />
        </StyledContainer>
    );
};
