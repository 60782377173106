import { FC, PropsWithChildren, createContext, useContext } from 'react';

import { statisticInitialState, useStatisticState } from '@/hooks/useStatisticState';
import { parameterLabelByValue } from '@/components/StatisticContent';

type StatisticStateReturn = ReturnType<typeof useStatisticState>;

const noop = () => {};

const initialValue: StatisticStateReturn = {
    statisticState: statisticInitialState,
    parameterLabel: parameterLabelByValue(statisticInitialState.parameter),
    isStateInitial: true,
    handleAggregationChange: noop,
    handleDomainsChange: noop,
    handleTabIndexChange: noop,
    handleParameterChange: noop,
    handlePeriodChange: noop,
    handleUsersPeriodChange: noop,
    handleUsersAggregationChange: noop,
};

const StatisticsStateContext = createContext(initialValue);

export const StatisticsStateProvider: FC<PropsWithChildren> = ({ children }) => {
    const state = useStatisticState();

    return <StatisticsStateContext.Provider value={state}>{children}</StatisticsStateContext.Provider>;
};

export const useStatisticContext = () => {
    return useContext(StatisticsStateContext);
};
