import styled, { css } from 'styled-components';
import { bodyM } from '@salutejs/plasma-typo';
import { surfaceSolid03, warning, accent, secondary, blackPrimary } from '@salutejs/plasma-tokens';
import { IconChevronDown } from '@salutejs/plasma-icons';
import { Button, BodyL } from '@salutejs/plasma-web';

import { ResizableTextarea } from '@/components/ResizableTextarea/ResizableTextarea';
import { MultiSelect } from '@/components/MultiSelect/MultiSelect';

export const fieldStyles = css`
    width: 100%;
    box-sizing: border-box;
    margin: 0;
    border-radius: 12px;
    border: 1px solid var(--plasma-colors-input-border);
    outline: none;
    padding: 14px 16px;
    ${bodyM};

    &:focus {
        border-color: ${accent};
    }
`;

export const StyledLabel = styled.label<{ withMarginTop?: boolean }>`
    position: relative;
    ${bodyM};

    & > div {
        margin-top: 0;

        &:first-child {
            margin-top: ${({ withMarginTop = true }) => (withMarginTop ? '10px' : '0')};
        }
    }
`;

export const StyledError = styled.div`
    color: ${warning};
`;

export const StyledDragArea = styled.div`
    position: relative;
    height: 156px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
    border: 1px dashed ${surfaceSolid03};
    ${bodyM};
    color: ${secondary};
`;

export const AccentSpan = styled.span`
    color: ${accent};
`;

export const StyledInput = styled.input`
    ${fieldStyles};

    &[type='file'] {
        position: absolute;
        opacity: 0;
        width: 100%;
        height: 100%;
        cursor: pointer;
    }
`;

export const StyledTogglePasswordButton = styled(Button)<{ isVisible?: boolean }>`
    position: absolute;
    bottom: 1px;
    right: 1px;
    background: transparent !important;

    &::after {
        content: '';
        visibility: ${({ isVisible }) => (isVisible ? 'hidden' : 'visible')};
        position: absolute;
        width: 2px;
        height: 50%;
        transform: rotate(-45deg);
        background: ${blackPrimary};
    }
`;

export const StyledTextArea = styled(ResizableTextarea)`
    &::after,
    & textarea {
        ${fieldStyles};
    }

    & textarea:focus {
        border-color: ${accent};
    }
`;

export const StyledSelect = styled.select`
    appearance: none;
    cursor: pointer;
    ${fieldStyles};
`;

export const FieldWrapper = styled.div`
    position: relative;
`;

export const StyledChevron = styled(IconChevronDown)`
    pointer-events: none;
    position: absolute;
    color: ${secondary};
    right: 24px;
    bottom: 24px;
    transform: translate(50%, 50%);
`;

export const StyledMultiSelect = styled(MultiSelect)`
    & button {
        ${fieldStyles};
        height: 50px;

        & svg {
            transform: scale(1.5);
        }
    }
`;

export const ErrorText = styled(BodyL)`
    color: ${warning};
    margin: 16px 32px;
`;
