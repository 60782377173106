import { commonT, nullText } from './common';

import { GetQuestionsResponse } from '@/api/types/questions';
import { Field } from '@/components/Forms/types';

type Attributes = keyof GetQuestionsResponse['data'][number]['attributes'];

export type SubmittedFormData = {
    answers: { value: string; isChecked: boolean; id?: number }[];
    question_type: 'open' | 'closed' | 'generative';
    question_text: string;
} & {
    disciplines_id: string;
    domain_id: string;
};

export const QUESTION_TYPES = [
    { value: 'open', label: 'Открытый' },
    { value: 'closed', label: 'Закрытый' },
    { value: 'generative', label: 'Генеративный' },
] as const;

export type Radio = (typeof QUESTION_TYPES)[number]['value'];

export const QUESTION_TO_ANSWER_LABEL_MAP = {
    open: 'Варианты правильных ответов',
    closed: 'Ответ (отметьте правильный)',
    generative: 'Вариант ответа (опционально)',
};

export const defaultQuestionValues = {
    answers: [{ value: '', isChecked: false }],
    question_type: 'open' as Radio,
    question_text: '',
};

const t: Record<Attributes, string> = {
    ...commonT,
    question_text: 'Текст вопроса',
    answer_gigachat: 'Ответ от гигачата',
    response_rating: 'Оценка ответа',
    question_type: 'Тип вопроса',
    answers_text: 'Текст ответов',
    version: 'Версия',
    uuid: '',
    // author_id: '',
    // checker_id: '',
};

export const questionTableColumns: Attributes[] = [
    'status',
    'domain_id',
    'subdomain_id',
    'disciplines_id',
    'question_type',
    'question_text',
    'answers_text',
    'answer_gigachat',
    'response_rating',
    'createdAt',
    'updatedAt',
    // 'divisions_id'
];

export const translateQuestionAttribute = (attribute: Attributes) =>
    attribute in t ? t[attribute as Attributes] : attribute;

export const addQuestionFormFields: Field<'domain_id' | 'subdomain_id' | 'disciplines_id'>[] = [
    {
        element: 'select',
        name: 'domain_id',
        placeholder: 'Выберите',
        label: translateQuestionAttribute('domain_id'),
        getOptions: () => [],
    },
    {
        element: 'select',
        name: 'subdomain_id',
        parentName: 'domain_id',
        placeholder: 'Выберите (после выбора домена)',
        label: translateQuestionAttribute('subdomain_id'),
        getOptions: () => [],
    },
    {
        element: 'select',
        name: 'disciplines_id',
        label: translateQuestionAttribute('disciplines_id'),
        placeholder: 'Выберите (после выбора поддомена)',
        parentName: 'subdomain_id',
        getOptions: () => [],
    },
];

export const editQuestionFormFields: Field<'response_rating' | 'status' | 'createdAt' | 'updatedAt'>[] = [
    {
        name: 'response_rating',
        label: translateQuestionAttribute('response_rating'),
        element: 'textarea',
        disabled: true,
    },
    { name: 'status', label: translateQuestionAttribute('status'), element: 'textarea', disabled: true },
    {
        name: 'createdAt',
        label: translateQuestionAttribute('createdAt'),
        element: 'textarea',
        disabled: true,
    },
    {
        name: 'updatedAt',
        label: translateQuestionAttribute('updatedAt'),
        element: 'textarea',
        disabled: true,
    },
    // {
    //     name: 'divisions_id',
    //     label: translateQuestionAttribute('divisions_id'),
    //     element: 'textarea',
    //     disabled: true,
    // },
];

type GigaField = Field<`answer_gigachat-${number}`>;

export const defaultGigaAnswerField: GigaField = {
    element: 'textarea',
    name: 'answer_gigachat-0',
    label: translateQuestionAttribute('answer_gigachat'),
    defaultValue: nullText,
    disabled: true,
};

export const getGigaFields = (question: GetQuestionsResponse['data'][number]): GigaField[] =>
    question?.attributes.answer_gigachat.data.length
        ? question.attributes.answer_gigachat.data.map((gigaAnswer, i) => ({
              element: 'textarea',
              name: `answer_gigachat-${gigaAnswer.id}`,
              label: i === 0 ? translateQuestionAttribute('answer_gigachat') : '',
              defaultValue: gigaAnswer.attributes.answer_text,
              disabled: true,
          }))
        : [defaultGigaAnswerField];

export type Fields = Array<
    (typeof addQuestionFormFields)[number] | (typeof editQuestionFormFields)[number] | GigaField
>;
