var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"ssu@0.16.0"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// istanbul ignore file
// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

import { stand } from '@/utils/env';

const SENTRY_DSN = 'https://e22adaa13a97428fa62bf703beca4cfd@sentry-api.sberdevices.ru/111';
const RELEASE = process.env.RELEASE || process.env.SENTRY_RELEASE;

const prepareBranch = (branch = '') => {
    if (branch.startsWith('release')) {
        return branch
            .replace(/^release\//, '')
            .replace(/\//g, '@')
            .replace(/\\/g, '@');
    }

    return `SSU@${branch}`;
};

const browserTracingIntegration = new Sentry.BrowserTracing({});

Sentry.init({
    dsn: stand === 'ift' || stand === 'prom' ? SENTRY_DSN : undefined,
    environment: stand,
    release: prepareBranch(RELEASE),
    tracesSampleRate: 1,
    integrations: [browserTracingIntegration],
    initialScope: {
        tags: { version: RELEASE },
    },
    beforeBreadcrumb(breadcrumb) {
        if (typeof breadcrumb.data?.url === 'string' && breadcrumb.data.url.includes('yandex.ru')) {
            return null;
        }

        if (breadcrumb.message?.includes('WebTelemetry')) {
            return null;
        }

        if (breadcrumb.category === 'amplitude') {
            return null;
        }

        return breadcrumb;
    },
});
