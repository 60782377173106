import { stringify } from 'qs';

import { PAGE_SIZE } from '@/constants/common';

const fields = ['name', 'block', 'subdomain_name', 'subdomain_id', 'domain_id', 'updatedAt'];

export const disciplineQueryObject = {
    pagination: {
        pageSize: PAGE_SIZE,
        page: 1,
    },
    populate: {
        subdomain_id: {
            fields,
            populate: {
                domain_id: {
                    fields,
                },
            },
        },
        divisions_id: {
            fields,
        },
    },
};

export const disciplineQuery = stringify(disciplineQueryObject);
