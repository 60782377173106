import React from 'react';
import styled from 'styled-components';
import { H2, H4 } from '@salutejs/plasma-web';
import { accent, secondary } from '@salutejs/plasma-tokens';

const StyledContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;

const StyledCount = styled(H2)`
    color: ${accent};
`;

const StyledSum = styled(H4)`
    color: ${secondary};
`;

type StatisticTitleTotalProps = {
    title: string;
    count: string | number;
};

export const StatisticTitleTotal: React.FC<StatisticTitleTotalProps> = ({ title, count }) => {
    return (
        <StyledContainer>
            <H2 bold>{title}:</H2>
            <StyledCount bold>{count}</StyledCount>
            <StyledSum>(сумма)</StyledSum>
        </StyledContainer>
    );
};
