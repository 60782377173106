import React from 'react';
import styled from 'styled-components';
import { surfaceSolid03, accent, secondary } from '@salutejs/plasma-tokens';
import { BodyM, Button } from '@salutejs/plasma-web';

import { DownloadButton } from '../DownloadButton/DownloadButton';

import { DownloadData } from '@/hooks/useStatisticsPage';

const StyledTabs = styled.div`
    position: relative;
    border-bottom: 1px solid ${surfaceSolid03};
    display: flex;
    margin: 0 -16px;
    padding: 0 16px 16px;
`;

const StyledTab = styled(Button)<{ isActive: boolean }>`
    background: transparent !important;
    padding: 0 16px;
    height: auto;
    color: ${({ isActive }) => (isActive ? accent : secondary)} !important;
`;

type CalculatedStyles = {
    cLeft: number;
    cWidth: number;
    isSafari?: boolean;
};

const StyledIndicator = styled.div<CalculatedStyles>`
    position: absolute;
    bottom: -1px;
    left: 0;
    background-color: ${accent};
    height: 2px;
    width: 1px;

    transform: ${({ cWidth, cLeft, isSafari }) =>
        `translateX(${(isSafari ? cWidth : cWidth / 2) + cLeft}px) scaleX(${cWidth})`};
    will-change: transform;
    transition: transform 100ms linear;
`;

type StatisticTabsProps = {
    activeTabIndex: number;
    setActiveTabIndex: (i: number) => void;
    downloadData?: DownloadData;
    isAdmin?: boolean;
};

export const StatisticTabs: React.FC<StatisticTabsProps> = ({
    activeTabIndex,
    setActiveTabIndex,
    downloadData,
    isAdmin,
}) => {
    const tabsRef = React.useRef<(HTMLElement | null)[]>([]);
    const [calculatedStyles, setCalculatedStyles] = React.useState<CalculatedStyles>({ cLeft: 0, cWidth: 0 });

    const tabs = isAdmin ? ['Общая сводка', 'Данные по пользователям'] : ['Общая сводка'];

    React.useEffect(() => {
        const currentTab = tabsRef.current[activeTabIndex];

        if (currentTab) {
            setCalculatedStyles({
                cLeft: currentTab.offsetLeft,
                cWidth: currentTab.clientWidth,
                isSafari:
                    !/chrome|chromium|crios/i.test(window.navigator.userAgent) &&
                    /safari/i.test(window.navigator.userAgent),
            });
        }
    }, [activeTabIndex]);

    return (
        <StyledTabs>
            {tabs.map((tab, i) => (
                <StyledTab
                    key={i}
                    ref={(el) => {
                        tabsRef.current[i] = el;
                    }}
                    isActive={activeTabIndex === i}
                    onClick={() => {
                        setActiveTabIndex(i);
                    }}
                >
                    <BodyM bold={activeTabIndex === i}>{tab}</BodyM>
                </StyledTab>
            ))}
            <StyledIndicator {...calculatedStyles} />
            {downloadData ? <DownloadButton data={downloadData} /> : null}
        </StyledTabs>
    );
};
