// https://til.simonwillison.net/css/resizing-textarea

import React from 'react';
import styled from 'styled-components';
import { UseFormRegisterReturn } from 'react-hook-form';

const Wrapper = styled.div`
    display: grid;

    &::after,
    & textarea {
        grid-area: 1 / 1 / 2 / 2;
        resize: none;
    }

    &::after {
        content: attr(data-replicated-value) ' ';
        white-space: pre-wrap;
        visibility: hidden;
    }
`;

type Props = Omit<UseFormRegisterReturn, 'ref'> & { placeholder?: string; className?: string };

const renderTextarea: React.ForwardRefRenderFunction<HTMLTextAreaElement, Props> = (props, ref) => {
    const handleInput = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        (e.target.parentNode as HTMLElement).dataset.replicatedValue = e.target.value;
    };

    return (
        <Wrapper className={props.className}>
            <textarea {...props} className="" ref={ref} rows={1} onInput={handleInput} />
        </Wrapper>
    );
};

export const ResizableTextarea = React.forwardRef(renderTextarea);
