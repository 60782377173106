import { PAGE_SIZE } from '@/constants/common';
import { TableState } from '@/hooks/useTableState';

const idToNameMap: Record<string, string> = {
    domain_id: 'name',
    subdomain_id: 'subdomain_name',
    content_data: 'name',
    answer_gigachat: 'answer_text',
    answers_text: 'answer_text',
    divisions_id: 'name',
    disciplines_id: 'name',
};

const getDateFilter = (dates: string[]) => {
    const start = new Date(dates[0]);
    const end = new Date(dates.length === 1 ? dates[0] : dates[1]);

    start.setHours(0, 0, 0, 0);
    end.setHours(23, 59, 59, 99);

    return {
        createdAt: {
            $gte: start,
            $lte: end,
        },
    };
};

export const getFilter = (filter: string, value: string[]) => {
    if (!value.length) {
        return undefined;
    }

    if (value.length === 1) {
        return {
            [filter]: { id: value[0] },
        };
    }

    return {
        [filter]: {
            $or: value.map((id) => ({ id })),
        },
    };
};

export const tableStateToQuery = (queryKey: string, state: Partial<TableState>) => {
    const pagination = state.pageIndex ? { page: state.pageIndex, pageSize: PAGE_SIZE } : { page: 1, pageSize: 100 };

    const sortQuery = state.sorting;
    const filtersQuery = state.filters;
    const searchQuery = state.search;

    const sort = (() => {
        if (!sortQuery || !sortQuery.id) {
            return undefined;
        }

        const value = `${sortQuery.desc ? 'desc' : 'asc'}`;
        const relation = idToNameMap[sortQuery.id];

        if (relation) {
            return {
                [sortQuery.id]: {
                    [relation]: value,
                },
            };
        }

        return { [sortQuery.id]: value };
    })();

    const search = (() => {
        if (!searchQuery) {
            return undefined;
        }

        const $containsi = searchQuery;

        switch (queryKey) {
            case 'contents':
                return { description: { $containsi } };
            case 'questions':
                return {
                    $or: [
                        { question_text: { $containsi } },
                        { answers_text: { answer_text: { $containsi } } },
                        { answer_gigachat: { answer_text: { $containsi } } },
                    ],
                };
            case 'disciplines':
                return { name: { $containsi } };
            default:
                return undefined;
        }
    })();

    const filters = Object.entries(filtersQuery || {}).map(([filter, value]) => {
        if (!value.length) {
            return undefined;
        }

        if (filter === 'createdAt') {
            return getDateFilter(value);
        }

        return getFilter(filter, value);
    });

    const allFilters = { $and: [...filters, search].filter(Boolean) };

    return {
        pagination,
        sort,
        filters: allFilters,
    };
};

export type StrapiQuery = ReturnType<typeof tableStateToQuery>;
