import { useCallback, useState } from 'react';

const filters = ['domain_id', 'subdomain_id', 'disciplines_id', 'createdAt', 'divisions_id'] as const;

export type Filter = (typeof filters)[number];
type Filters = Record<Filter, string[]>;

export const initialFilters = filters.reduce((acc, cur) => ({ ...acc, [cur]: [] }), {}) as Filters;

export const tableInitialState = {
    pageIndex: 1,
    sorting: { id: '', desc: false },
    search: '',
    filters: initialFilters,
};

export type TableState = typeof tableInitialState;

const getIsStateInitial = (state: typeof tableInitialState) =>
    state.pageIndex === tableInitialState.pageIndex &&
    state.sorting.id === tableInitialState.sorting.id &&
    state.sorting.desc === tableInitialState.sorting.desc &&
    state.search === tableInitialState.search &&
    filters.every((filter) => state.filters[filter].length === 0);

export const useTablesState = () => {
    const [tableState, setTableState] = useState(tableInitialState);

    const onPageChange = useCallback(
        (newPageIndex: number) =>
            setTableState((prev) => {
                if (prev.pageIndex === newPageIndex) {
                    return prev;
                }

                return { ...prev, pageIndex: newPageIndex };
            }),
        [],
    );

    const onSortingChange = useCallback(
        (s: TableState['sorting'][]) =>
            setTableState((prev) => {
                const prevSorting = prev.sorting;
                const newSorting = s.at(0);

                if (!newSorting || (prevSorting.id === newSorting.id && prevSorting.desc === newSorting.desc)) {
                    return prev;
                }

                return { ...prev, sorting: newSorting };
            }),
        [],
    );

    const onSearchChange = useCallback((s: string) => {
        setTableState((prev) => ({ ...prev, pageIndex: tableInitialState.pageIndex, search: s }));
    }, []);

    const onFilterChange = useCallback((filter: Filter, value: string[]) => {
        setTableState((prev) => {
            /* eslint-disable camelcase */
            let { subdomain_id, disciplines_id } = prev.filters;

            if (filter === 'domain_id') {
                subdomain_id = tableInitialState.filters.subdomain_id;
                disciplines_id = tableInitialState.filters.disciplines_id;
            }

            if (filter === 'subdomain_id') {
                disciplines_id = tableInitialState.filters.disciplines_id;
            }

            return {
                ...prev,
                pageIndex: tableInitialState.pageIndex,
                filters: { ...prev.filters, subdomain_id, disciplines_id, [filter]: value },
            };
            /* eslint-enable camelcase */
        });
    }, []);

    return {
        tableState,
        isStateInitial: getIsStateInitial(tableState),
        onPageChange,
        onSortingChange,
        onSearchChange,
        onFilterChange,
    };
};
