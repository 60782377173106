import { stringify } from 'qs';

import { StrapiQuery } from '../utils/tableStateToQuery';

import { MethodPaths } from './types';
import { GetQuestionsResponse } from './types/questions';
import { GetContentsResponse } from './types/contents';
import { GetDisciplinesResponse } from './types/disciplines';

import { sendClientRequest } from '@/api/sendRequest';

type Response = GetQuestionsResponse | GetContentsResponse | GetDisciplinesResponse;

export const fetchAllData = async <R extends Response>(
    query: Partial<StrapiQuery>,
    path: MethodPaths,
    page = 1,
    currentLength = 0,
): Promise<R['data']> => {
    const queryWithPage = {
        ...query,
        pagination: {
            page,
            pageSize: 100,
        },
    };

    const res = (await sendClientRequest({
        path,
        method: 'GET',
        params: undefined,
        query: stringify(queryWithPage),
    })) as R;

    if (!res || !('data' in res) || !('meta' in res) || !Array.isArray(res.data)) {
        throw new Error('fetchAllData wrong request');
    }

    const totalLength = currentLength + res.data.length;

    if (totalLength >= res.meta.pagination.total) {
        return res.data;
    }

    return fetchAllData(query, path, page + 1, totalLength).then((res2) => [...res.data, ...res2]) as Promise<
        R['data']
    >;
};
