export const PARAMETERS = [
    { label: 'Контент', value: 'content' } as const,
    { label: 'Размер контента', value: 'contentVolume' } as const,
    { label: 'Тестовые вопросы', value: 'questions' } as const,
];

export const AGGREGATIONS = [
    { label: 'День', value: 'day' } as const,
    { label: 'Неделя', value: 'week' } as const,
    { label: 'Месяц', value: 'month' } as const,
    // { label: 'Квартал', value: 'quarter' } as const,
    { label: 'Год', value: 'year' } as const,
];

export type ParameterT = (typeof PARAMETERS)[number]['value'];
export type AggregationT = (typeof AGGREGATIONS)[number]['value'];

export const parameterLabelByValue = (value: string) => PARAMETERS.find((p) => p.value === value)?.label || value;
