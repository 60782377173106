import { stringify } from 'qs';

import { StatisticState } from '@/hooks/useStatisticState';
import { getFilter } from '@/utils/tableStateToQuery';

export const statisticsStateToQuery = (
    domains: StatisticState['selectedDomainIds'],
    aggregation: StatisticState['aggregation'],
    period: StatisticState['period'],
    usersAggregation: StatisticState['usersAggregation'],
    usersPeriod: StatisticState['usersPeriod'],
) => {
    return stringify({
        populate: '*',
        filters: { $and: [getFilter('domain_id', domains)] },
        period,
        usersPeriod,
        aggregation,
        usersAggregation,
    });
};
