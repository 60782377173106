import { FC, PropsWithChildren, createContext, useContext, useMemo } from 'react';

import { tableInitialState, useTablesState } from '@/hooks/useTableState';

type TableStateReturn = ReturnType<typeof useTablesState>;

export type TablesStateContextValue = {
    contents: TableStateReturn;
    questions: TableStateReturn;
    disciplines: TableStateReturn;
};

const noop = () => {};

const initialValue: TableStateReturn = {
    isStateInitial: true,
    tableState: tableInitialState,
    onPageChange: noop,
    onSortingChange: noop,
    onSearchChange: noop,
    onFilterChange: noop,
};

const initialContextValue: TablesStateContextValue = {
    contents: initialValue,
    questions: initialValue,
    disciplines: initialValue,
};

const TablesStateContext = createContext(initialContextValue);

export const TablesStateProvider: FC<PropsWithChildren> = ({ children }) => {
    const contents = useTablesState();
    const questions = useTablesState();
    const disciplines = useTablesState();

    const value = useMemo(
        () => ({
            contents,
            questions,
            disciplines,
        }),
        [contents, questions, disciplines],
    );

    return <TablesStateContext.Provider value={value}>{children}</TablesStateContext.Provider>;
};

export const useAllTablesStateContext = () => {
    return useContext(TablesStateContext);
};

export const useTablesStateContext = (table: keyof TablesStateContextValue) => {
    return useAllTablesStateContext()[table];
};
