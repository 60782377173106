import { QueryFunctionContext } from 'react-query';
import { stringify } from 'qs';

import { COOKIE_NAME } from '@/utils/env';
import { disciplineQueryObject } from '@/utils/query';
import { TableState, initialFilters } from '@/hooks/useTableState';
import { tableStateToQuery } from '@/utils/tableStateToQuery';
import { StatisticState } from '@/hooks/useStatisticState';
import { statisticsStateToQuery } from '@/utils/statisticsStateToQuery';

type Fn<T> = (token?: string, query?: string, id?: string) => Promise<T>;

const parseCookies = (cookiesString: string | undefined) => {
    const cookies: Record<string, string> = {};

    cookiesString?.split(';').forEach((cookie) => {
        const [key, value] = cookie.split('=').map((c) => c.trim());
        cookies[key] = value;
    });

    return cookies;
};

export const getCookieOnClient = () => parseCookies(document.cookie)[COOKIE_NAME];

export const queryFunction = <T>(fn: Fn<T>): ((context: QueryFunctionContext | string | undefined) => Promise<T>) => {
    return (contextOrToken) => {
        if (typeof contextOrToken === 'string') {
            return fn(contextOrToken);
        }

        const token = getCookieOnClient();
        const qKey = contextOrToken?.queryKey.at(0) as string;

        if (qKey === 'statistics') {
            return fn(
                token,
                statisticsStateToQuery(
                    contextOrToken?.queryKey.at(1) as StatisticState['selectedDomainIds'],
                    contextOrToken?.queryKey.at(2) as StatisticState['aggregation'],
                    contextOrToken?.queryKey.at(3) as StatisticState['period'],
                    contextOrToken?.queryKey.at(4) as StatisticState['usersAggregation'],
                    contextOrToken?.queryKey.at(5) as StatisticState['usersPeriod'],
                ),
            );
        }

        if (qKey === 'subdomain') {
            const id = contextOrToken?.queryKey.at(1) as string;

            return fn(token, 'populate[0]=disciplines_id', id);
        }

        if (contextOrToken?.queryKey.length === 1) {
            return fn(token, 'populate=*&pagination[pageSize]=100&pagination[page]=1');
        }

        if (contextOrToken?.queryKey.length === 2) {
            const ids = contextOrToken?.queryKey.at(1) as string[] | undefined;

            if (ids) {
                const filters = {
                    ...initialFilters,
                    domain_id: qKey === 'subdomains' ? ids : initialFilters.domain_id,
                    subdomain_id: qKey === 'subdomains' ? initialFilters.subdomain_id : ids,
                };

                const stateQuery = tableStateToQuery(qKey, {
                    pageIndex: undefined,
                    sorting: undefined,
                    search: undefined,
                    filters,
                });

                const query = stringify({
                    ...stateQuery,
                    populate: qKey === 'disciplines' ? disciplineQueryObject.populate : '*',
                });

                return fn(token, query);
            }
        }

        const stateQuery = tableStateToQuery(qKey, {
            pageIndex: contextOrToken?.queryKey.at(1) as TableState['pageIndex'] | undefined,
            sorting: contextOrToken?.queryKey.at(2) as TableState['sorting'] | undefined,
            search: contextOrToken?.queryKey.at(3) as TableState['search'] | undefined,
            filters: contextOrToken?.queryKey.at(4) as TableState['filters'] | undefined,
        });

        const query = stringify({
            ...stateQuery,
            populate: qKey === 'disciplines' ? disciplineQueryObject.populate : '*',
        });

        return fn(token, query);
    };
};
