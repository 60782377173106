import { getCookieOnClient } from './queries/queryFunction';
import { Fetcher } from './types';

import { transliterate } from '@/utils/transliterate';
import { backendHost } from '@/utils/env';

const handleStrapiResponse = (promise: Promise<Response>) =>
    promise
        .then((res) => res.json())
        .then((json) => {
            if (json.error) {
                throw json.error;
            }

            return json;
        });

type FileFormData = {
    description: string;
    disciplines_id: string;
    domain_id: string;
    domain_name: string;
    specialization_name: string;
    subdomain_name: string;
    disciplines_name: string;
    content_data?: FileList;
};

export const sendFormDataRequest = async (data: FileFormData, editingId?: string | number) => {
    const headers = new Headers();
    headers.append('Authorization', `Bearer ${getCookieOnClient()}`);

    const contentsHeaders = new Headers(headers);
    contentsHeaders.append('Content-Type', 'application/json');

    const file = data.content_data?.item(0);
    const fileName = file?.name.replace(/\.[^/.]+$/, '');
    const filePath = `${transliterate(data.domain_name)}/${transliterate(data.specialization_name)}`;

    const contentPromise = handleStrapiResponse(
        fetch(`${backendHost}/ssu-contents${editingId ? `/${editingId}` : ''}`, {
            method: editingId ? 'PUT' : 'POST',
            headers: contentsHeaders,
            body: JSON.stringify({ data }),
        }),
    );

    if (file) {
        const content = await contentPromise;

        const jsonString = JSON.stringify({ ...content.data, requestParams: data }, null, 2);
        const jsonBlob = new Blob([jsonString], { type: 'text/plain' });
        const jsonFormData = new FormData();

        jsonFormData.append('ref', 'api::ssu-content.ssu-content');
        jsonFormData.append('field', 'json');
        jsonFormData.append('refId', content.data.id);
        jsonFormData.append('files', jsonBlob, `${fileName}.json`);
        jsonFormData.append('path', filePath);

        await handleStrapiResponse(
            fetch(`${backendHost}/upload/`, {
                method: 'POST',
                headers,
                body: jsonFormData,
            }),
        );

        const formData = new FormData();

        formData.append('ref', 'api::ssu-content.ssu-content');
        formData.append('field', 'content_data');
        formData.append('refId', content.data.id);
        formData.append('files', file, file.name);
        formData.append('path', filePath);

        return handleStrapiResponse(
            fetch(`${backendHost}/upload/`, {
                method: 'POST',
                headers,
                body: formData,
            }),
        );
    }

    return contentPromise;
};

export const sendRequest: Fetcher = ({ path, params, token, query = '', method = 'POST', id }) => {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');

    if (token) {
        headers.append('Authorization', `Bearer ${token}`);
    }

    const host = path.startsWith('/api/') ? '' : backendHost;

    return handleStrapiResponse(
        fetch(`${host}${id ? `${path}/${id}` : path}${query ? `?${query}` : ''}`, {
            headers,
            method,
            body: JSON.stringify(params),
        }),
    );
};

export const sendClientRequest: Fetcher = (params) => sendRequest({ ...params, token: getCookieOnClient() });
