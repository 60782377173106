import { valueToDate, writeToFile } from './utils';

import { TableState } from '@/hooks/useTableState';
import { tableStateToQuery } from '@/utils/tableStateToQuery';
import { fetchAllData } from '@/api/fetchAllData';
import { GetQuestionsResponse } from '@/api/types/questions';
import { QUESTION_TYPES, translateQuestionAttribute } from '@/constants/questions';

export const mapQuestionsToFlatArray = (data: GetQuestionsResponse['data']) =>
    data.map((question) =>
        Object.entries(question.attributes).reduce<Record<string, string | Date | number | null>>(
            (acc, [a, value]) => {
                const attribute = a as keyof typeof question.attributes;
                const translatedAttribute = translateQuestionAttribute(attribute);

                if (!(translatedAttribute in acc)) {
                    return acc;
                }

                if (typeof value === 'string') {
                    if (attribute === 'question_type') {
                        acc[translatedAttribute] = QUESTION_TYPES.find((q) => q.value === value)?.label || null;
                    } else if (attribute === 'createdAt' || attribute === 'updatedAt') {
                        acc[translatedAttribute] = valueToDate(value);
                    } else {
                        acc[translatedAttribute] = value;
                    }
                } else if (!value?.data) {
                    acc[translatedAttribute] = null;
                } else if (Array.isArray(value.data)) {
                    acc[translatedAttribute] =
                        value.data
                            .map((relation) =>
                                'name' in relation.attributes
                                    ? relation.attributes.name
                                    : relation.attributes.answer_text,
                            )
                            .join(', ') || null;
                } else {
                    const name =
                        'name' in value.data.attributes
                            ? value.data.attributes.name
                            : value.data.attributes.subdomain_name;
                    acc[translatedAttribute] = name || null;
                }

                return acc;
            },
            {
                id: question.id,
                [translateQuestionAttribute('domain_id')]: null,
                [translateQuestionAttribute('subdomain_id')]: null,
                [translateQuestionAttribute('disciplines_id')]: null,
                [translateQuestionAttribute('status')]: null,
                [translateQuestionAttribute('question_type')]: null,
                [translateQuestionAttribute('question_text')]: null,
                [translateQuestionAttribute('answers_text')]: null,
                'Правильные ответы': question.attributes.answers_text.data
                    .filter((q) => q.attributes.is_correct)
                    .map((q) => q.attributes.answer_text)
                    .join(', '),
                [translateQuestionAttribute('answer_gigachat')]: null,
                [translateQuestionAttribute('response_rating')]: null,
                [translateQuestionAttribute('createdAt')]: null,
                [translateQuestionAttribute('updatedAt')]: null,
            },
        ),
    );

export const downloadQuestions = (table: TableState, onSuccess: () => void, onError: (text?: string) => void) => {
    const tableQuery = tableStateToQuery('questions', table);
    const query = {
        ...tableQuery,
        populate: '*',
    };

    fetchAllData<GetQuestionsResponse>(query, '/ssu-questions')
        .then((rawData) => {
            const data = mapQuestionsToFlatArray(rawData);

            if (!data.length) {
                onError('Нет данных');

                return;
            }

            writeToFile(data, 'questions');
            onSuccess();
        })
        .catch(() => {
            onError();
        });
};
