import { valueToDate, writeToFile } from './utils';

import { disciplineQueryObject } from '@/utils/query';
import { GetDisciplinesResponse } from '@/api/types/disciplines';
import { translateDisciplineAttribute } from '@/constants/disciplines';
import { TableState } from '@/hooks/useTableState';
import { tableStateToQuery } from '@/utils/tableStateToQuery';
import { fetchAllData } from '@/api/fetchAllData';

const mapDisciplinesToFlatArray = (data: GetDisciplinesResponse['data']) =>
    data.map((discipline) =>
        Object.entries(discipline.attributes).reduce<Record<string, string | Date | number | null>>(
            (acc, [a, value]) => {
                const attribute = a as keyof typeof discipline.attributes;
                const translatedAttribute = translateDisciplineAttribute(attribute);

                if (typeof value === 'string') {
                    acc[translatedAttribute] =
                        attribute === 'createdAt' || attribute === 'updatedAt' ? valueToDate(value) : value;
                } else if (!value?.data) {
                    acc[translatedAttribute] = null;
                } else if (Array.isArray(value.data)) {
                    acc[translatedAttribute] =
                        value.data.map((relation) => relation.attributes.name).join(', ') || null;
                }

                return acc;
            },
            {
                id: discipline.id,
                [translateDisciplineAttribute('name')]: null,
                [translateDisciplineAttribute('subdomain_id')]:
                    discipline.attributes.subdomain_id.data?.attributes.subdomain_name || null,
                [translateDisciplineAttribute('domain_id')]:
                    discipline.attributes.subdomain_id.data?.attributes.domain_id.data?.attributes.name || null,
            },
        ),
    );

export const downloadDisciplines = (table: TableState, onSuccess: () => void, onError: (text?: string) => void) => {
    const tableQuery = tableStateToQuery('disciplines', table);
    const query = {
        ...tableQuery,
        populate: disciplineQueryObject.populate,
    };

    fetchAllData<GetDisciplinesResponse>(query, '/ssu-disciplines')
        .then((rawData) => {
            const data = mapDisciplinesToFlatArray(rawData);

            if (!data.length) {
                onError('Нет данных');

                return;
            }

            writeToFile(data, 'disciplines');
            onSuccess();
        })
        .catch(() => {
            onError();
        });
};
