import { H4 } from '@salutejs/plasma-web';
import React from 'react';

export const StatisticContentWrapper: React.FC<React.PropsWithChildren<{ title: string }>> = ({ title, children }) => {
    return (
        <div>
            <H4 bold>{title}</H4>
            {children}
        </div>
    );
};
