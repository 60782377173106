import React from 'react';
import { AppProps } from 'next/app';
import { ModalsProvider, ToastProvider } from '@salutejs/plasma-web';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import { GlobalStyle } from '@/styles/GlobalStyles';
import { TablesStateProvider } from '@/context/TablesStateContext';
import { StatisticsStateProvider } from '@/context/StatisticStateContext';
import { GigaChatTokenProvider } from '@/context/GigaChatTokenContext';

function MyApp({ Component, pageProps }: AppProps) {
    const [queryClient] = React.useState(
        () =>
            new QueryClient({
                defaultOptions: {
                    queries: {
                        // With SSR, we usually want to set some default staleTime
                        // above 0 to avoid refetching immediately on the client
                        staleTime: 60 * 1000,
                    },
                },
            }),
    );

    return (
        <QueryClientProvider client={queryClient}>
            <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
            <ModalsProvider>
                <ToastProvider>
                    <TablesStateProvider>
                        <StatisticsStateProvider>
                            <GigaChatTokenProvider>
                                <GlobalStyle />
                                <Component {...pageProps} />
                            </GigaChatTokenProvider>
                        </StatisticsStateProvider>
                    </TablesStateProvider>
                </ToastProvider>
            </ModalsProvider>
        </QueryClientProvider>
    );
}

export default MyApp;
