import { commonT } from './common';

import { addQuestionFormFields } from '@/constants/questions';
import { GetContentsResponse } from '@/api/types/contents';
import { Field } from '@/components/Forms/types';

type Attributes = keyof GetContentsResponse['data'][number]['attributes'];

export type SubmittedFormData = Record<Exclude<Attributes, 'content_data'>, string> & {
    content_data: FileList;
};

const t: Record<Attributes, string> = {
    ...commonT,
    content_data: 'Файл',
    description: 'Описание',
};

export const contentTableColumns: Attributes[] = [
    'status',
    'domain_id',
    'subdomain_id',
    'disciplines_id',
    'description',
    'content_data',
    // 'has_file',
    'createdAt',
    'updatedAt',
];

export const translateContentAttribute = (attribute: Attributes) =>
    attribute in t ? t[attribute as Attributes] : attribute;

const commonFormFields: Field<Attributes>[] = [
    ...addQuestionFormFields,
    {
        element: 'textarea',
        name: 'description',
        label: 'Описание',
        placeholder: 'Введите описание (опционально)',
        required: false,
    },
];

export const fileInputFiled: Field<'content_data'> = {
    element: 'input',
    type: 'file',
    name: 'content_data',
    label: 'Прикрепить файл',
    placeholder: 'Перетащите сюда или выберите файл',
    errorText: 'Прикрепите файл',
};

export const existingFileInputFiled: Field<'disabled_content_data'> = {
    element: 'input',
    type: 'text',
    name: 'disabled_content_data',
    label: 'Файл',
    disabled: true,
};

export const addContentFormFields: Field<Attributes>[] = [...commonFormFields, fileInputFiled];

export const editContentFormFields: Field<Attributes>[] = [
    ...commonFormFields,
    { name: 'status', label: translateContentAttribute('status'), element: 'textarea', disabled: true },
    {
        name: 'createdAt',
        label: translateContentAttribute('createdAt'),
        element: 'textarea',
        disabled: true,
    },
    {
        name: 'updatedAt',
        label: translateContentAttribute('updatedAt'),
        element: 'textarea',
        disabled: true,
    },
];
