import { utils, writeFile } from 'xlsx';

export const valueToDate = (value: string) => {
    const date = new Date(value);
    date.setMilliseconds(0);

    return date;
};

type Data = object[];

const getColumnsWidths = (data: Data) => Object.keys(data.at(0) || {}).map(() => ({ wch: 20 }));

export const writeToFile = (data: Data, name: string, statisticsData?: Data) => {
    const book = utils.book_new();

    if (statisticsData) {
        const statisticsSheet = utils.json_to_sheet(statisticsData);
        statisticsSheet['!cols'] = getColumnsWidths(data);
        utils.book_append_sheet(book, statisticsSheet, 'statistics');
    }

    const sheet = utils.json_to_sheet(data, { cellDates: true, dateNF: 'dd.mm.yy hh:mm:ss' });
    sheet['!cols'] = getColumnsWidths(data);

    utils.book_append_sheet(book, sheet, name);

    writeFile(book, `${statisticsData ? 'statistics-' : ''}${name}-${new Date().getTime()}.xls`, { cellStyles: true });
};
