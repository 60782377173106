import React from 'react';
import styled from 'styled-components';
import { CalendarBaseRange } from '@salutejs/plasma-web';
import { white } from '@salutejs/plasma-tokens';
import { IconCalendar, IconCrossCircle } from '@salutejs/plasma-icons';

import { StyledLabel, fieldStyles } from '../Forms/components/styled';

const StyledContainer = styled.div`
    position: relative;
`;

type Sizes = {
    totalHeight: number;
    paddingVertical: number;
    paddingHorizontal: number;
};

const Field = styled.div<Sizes>`
    ${fieldStyles};
    position: relative;
    display: block;
    cursor: pointer;
    padding: ${({ paddingVertical, paddingHorizontal }) => `${paddingVertical}px ${paddingHorizontal}px`};
    height: ${({ totalHeight }) => totalHeight}px;
`;

const StyledCalendarIcon = styled(IconCalendar)<Sizes>`
    position: absolute;
    right: ${({ paddingHorizontal }) => paddingHorizontal}px;
    top: ${({ paddingVertical }) => paddingVertical - 2}px;
`;

const StyledCrossIcon = styled(IconCrossCircle)<Sizes>`
    position: absolute;
    right: ${({ paddingHorizontal }) => paddingHorizontal}px;
    top: ${({ paddingVertical }) => paddingVertical - 2}px;
`;

const StyledPopover = styled.div`
    display: block;
`;

const StyledCalendar = styled(CalendarBaseRange)`
    position: absolute;
    background: ${white};
    border-radius: 4px;
    box-shadow: 0 0.75rem 1.75rem rgba(0, 0, 0, 0.08);
`;

const today = new Date();
const yesterday = new Date();
yesterday.setDate(yesterday.getDate() - 1);

export type DatesStr = [(string | undefined)?, (string | undefined)?];
type Dates = [Date, Date];

type DatePickerProps = {
    onChange: (dates: DatesStr) => void;
    label?: string;
    initialDates?: DatesStr;
    className?: string;
    defaultValueText?: string;
    sizes?: Sizes;
};

const ALL_DATES = 'Любая';
const defaultDates: Dates = [yesterday, today];
const defaultSizes: Sizes = {
    totalHeight: 48,
    paddingVertical: 14,
    paddingHorizontal: 16,
};

const defaultValues = {
    text: ALL_DATES,
    value: defaultDates,
};

const dateByElement = (target: HTMLElement | null) => {
    const dateData = [
        Number(target?.getAttribute('data-year')),
        Number(target?.getAttribute('data-month-index')),
        Number(target?.getAttribute('data-day')),
    ];

    if (dateData.every((d, i) => !Number.isNaN(d) && (d > 0 || (d === 0 && i === 1)))) {
        return new Date(dateData[0], dateData[1], dateData[2]);
    }

    return undefined;
};

const valuesByString = (str?: string): typeof defaultValues => {
    if (!str) {
        return defaultValues;
    }

    const date = new Date(str);

    return {
        text: date.toLocaleDateString('ru-RU'),
        value: [date, date],
    };
};

const valuesByInitialDates = (initialDates?: DatesStr): typeof defaultValues => {
    switch (initialDates?.length) {
        case 0: {
            return defaultValues;
        }
        case 1: {
            return valuesByString(initialDates[0]);
        }
        case 2: {
            const dates = initialDates.filter(Boolean) as string[];

            if (dates.length === 0) {
                return defaultValues;
            }

            if (dates.length === 1) {
                return valuesByString(dates[0]);
            }

            const start = new Date(dates[0]);
            const end = new Date(dates[1]);

            if (start.getTime() >= end.getTime()) {
                return valuesByString(dates[0]);
            }

            return {
                text: `${start.toLocaleDateString('ru-Ru')} - ${end.toLocaleDateString('ru-Ru')}`,
                value: [start, end],
            };
        }
        default: {
            return defaultValues;
        }
    }
};

export const DatePicker: React.FC<DatePickerProps> = ({
    label,
    className,
    initialDates,
    defaultValueText = defaultValues.text,
    sizes = defaultSizes,
    onChange,
}) => {
    const initial = valuesByInitialDates(initialDates);

    const [isOpen, setIsOpen] = React.useState(false);
    const [textValue, setTextValue] = React.useState(initial.text);
    const [value, setValue] = React.useState(initial.value);

    const prevClickedDate = React.useRef<Date | null>(null);

    React.useEffect(() => {
        if (!isOpen) {
            prevClickedDate.current = null;
        }
    }, [isOpen]);

    React.useEffect(() => {
        setTextValue(initial.text);
        setValue(initial.value);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialDates]);

    const handleToggle = React.useCallback(() => {
        setIsOpen((p) => !p);
    }, []);

    const handleCalendarClick = React.useCallback(
        (e: React.MouseEvent<HTMLDivElement>) => {
            const target = e.target as HTMLDivElement;
            const dayStr = target.textContent;
            const day = Number(dayStr);

            if (!day || day < 0 || Number.isNaN(day)) {
                return;
            }

            const date = dateByElement(target) || dateByElement(target.parentElement);

            if (!date || date > today) {
                prevClickedDate.current = null;

                return;
            }

            if (!prevClickedDate.current || date.getTime() !== prevClickedDate.current.getTime()) {
                prevClickedDate.current = date;

                return;
            }

            setIsOpen(false);
            setValue([date, date]);
            setTextValue(date.toLocaleDateString('ru-Ru'));
            onChange([date.toISOString()]);
        },
        [onChange],
    );

    const handleOnChangeValue = React.useCallback(
        (newValue: [Date, (Date | undefined)?]) => {
            const [start, end] = newValue;

            if (!end) {
                return;
            }

            setIsOpen(false);
            setValue(newValue as Dates);
            setTextValue(`${start.toLocaleDateString('ru-Ru')} - ${end.toLocaleDateString('ru-Ru')}`);
            onChange([start.toISOString(), end.toISOString()]);
        },
        [onChange],
    );

    const handleCrossClick = React.useCallback(
        (e: React.MouseEvent) => {
            e.preventDefault();
            e.stopPropagation();

            setIsOpen(false);
            setTextValue(ALL_DATES);
            setValue(defaultDates);
            onChange([]);
        },
        [onChange],
    );

    return (
        <StyledContainer className={className}>
            <StyledLabel withMarginTop={!!label}>
                {label}
                <StyledPopover onClick={handleToggle}>
                    <Field {...sizes}>
                        {textValue === ALL_DATES ? defaultValueText : textValue}
                        {textValue === ALL_DATES ? (
                            <StyledCalendarIcon {...sizes} />
                        ) : (
                            <div onClick={handleCrossClick}>
                                <StyledCrossIcon {...sizes} />
                            </div>
                        )}
                    </Field>
                </StyledPopover>
            </StyledLabel>
            {isOpen ? (
                <StyledCalendar
                    type="Days"
                    value={value}
                    max={today}
                    onChangeValue={handleOnChangeValue}
                    onClick={handleCalendarClick}
                />
            ) : null}
        </StyledContainer>
    );
};
