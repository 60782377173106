import React from 'react';

import { parameterLabelByValue } from '@/components/StatisticContent';
import { DatesStr } from '@/components/DatePicker/DatePicker';
import { ParameterT, AggregationT } from '@/components/StatisticContent/constants';

export type StatisticState = {
    selectedDomainIds: string[];
    activeTabIndex: number;
    parameter: ParameterT;
    aggregation: AggregationT;
    period: DatesStr;
    isPeriodChanged: boolean;
    isUsersPeriodChanged: boolean;
    usersPeriod: DatesStr;
    usersAggregation: AggregationT;
};

const getInitialPeriod = (aggregation: AggregationT) => {
    const start = new Date();
    const end = new Date();

    switch (aggregation) {
        case 'day': {
            start.setDate(start.getDate() - 7);
            break;
        }
        case 'week': {
            start.setDate(start.getDate() - 21);

            while (start.getDay() !== 1) {
                start.setDate(start.getDate() - 1);
            }

            break;
        }
        case 'month': {
            start.setMonth(start.getMonth() - 5);
            break;
        }
        case 'year': {
            start.setFullYear(Math.max(start.getFullYear() - 5, 2023));
            break;
        }
        default: {
            break;
        }
    }

    return [start.toISOString(), end.toISOString()] as DatesStr;
};

const initialPeriod = getInitialPeriod('day');

export const statisticInitialState: StatisticState = {
    selectedDomainIds: [],
    activeTabIndex: 0,
    parameter: 'questions',
    aggregation: 'day',
    usersAggregation: 'day',
    period: initialPeriod,
    usersPeriod: initialPeriod,
    isPeriodChanged: false,
    isUsersPeriodChanged: false,
};

const getIsStateInitial = (state: StatisticState) =>
    state.selectedDomainIds.length === statisticInitialState.selectedDomainIds.length &&
    state.activeTabIndex === statisticInitialState.activeTabIndex &&
    state.parameter === statisticInitialState.parameter &&
    state.aggregation === statisticInitialState.aggregation &&
    state.period[0] === statisticInitialState.period[0] &&
    state.period[1] === statisticInitialState.period[1] &&
    state.usersAggregation === statisticInitialState.usersAggregation &&
    state.usersPeriod[0] === statisticInitialState.usersPeriod[0] &&
    state.usersPeriod[1] === statisticInitialState.usersPeriod[1];

export const useStatisticState = () => {
    const [statisticState, setStatisticState] = React.useState<StatisticState>(statisticInitialState);

    const handleDomainsChange = React.useCallback((domains: StatisticState['selectedDomainIds']) => {
        setStatisticState((prev) => ({ ...prev, selectedDomainIds: domains }));
    }, []);

    const handleTabIndexChange = React.useCallback((index: StatisticState['activeTabIndex']) => {
        setStatisticState((prev) => ({ ...prev, activeTabIndex: index }));
    }, []);

    const handleParameterChange = React.useCallback((parameter: StatisticState['parameter']) => {
        setStatisticState((prev) => ({ ...prev, parameter }));
    }, []);

    const handlePeriodChange = React.useCallback((period: StatisticState['period']) => {
        setStatisticState((prev) => ({
            ...prev,
            period: period.length ? period : getInitialPeriod(prev.aggregation),
            isPeriodChanged: !!period.length,
        }));
    }, []);

    const handleUsersPeriodChange = React.useCallback((period: StatisticState['usersPeriod']) => {
        setStatisticState((prev) => ({
            ...prev,
            usersPeriod: period.length ? period : getInitialPeriod(prev.usersAggregation),
            isUsersPeriodChanged: !!period.length,
        }));
    }, []);

    const handleAggregationChange = React.useCallback((aggregation: StatisticState['aggregation']) => {
        setStatisticState((prev) => ({
            ...prev,
            aggregation,
            period: prev.isPeriodChanged ? prev.period : getInitialPeriod(aggregation),
        }));
    }, []);

    const handleUsersAggregationChange = React.useCallback((usersAggregation: StatisticState['usersAggregation']) => {
        setStatisticState((prev) => ({
            ...prev,
            usersAggregation,
            usersPeriod: prev.isUsersPeriodChanged ? prev.usersPeriod : getInitialPeriod(usersAggregation),
        }));
    }, []);

    return {
        statisticState,
        parameterLabel: parameterLabelByValue(statisticState.parameter),
        isStateInitial: getIsStateInitial(statisticState),
        handleAggregationChange,
        handleDomainsChange,
        handleTabIndexChange,
        handleParameterChange,
        handlePeriodChange,
        handleUsersPeriodChange,
        handleUsersAggregationChange,
    };
};
