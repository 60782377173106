import React from 'react';
import styled from 'styled-components';
import { usePathname } from 'next/navigation';
import { captureException } from '@sentry/nextjs';
import { IconDownload } from '@salutejs/plasma-icons';
import { Button, useToast } from '@salutejs/plasma-web';
import { accent, surfaceCard } from '@salutejs/plasma-tokens';

import { downloadDisciplines } from './downloadDisciplines';
import { downloadContent } from './downloadContent';
import { downloadQuestions } from './downloadQuestions';
import { downloadStatistics } from './downloadStatistics';

import { Pathname } from '@/utils/routes';
import { errorText } from '@/constants/texts';
import { useAllTablesStateContext } from '@/context/TablesStateContext';
import { DownloadData } from '@/hooks/useStatisticsPage';
import { useStatisticContext } from '@/context/StatisticStateContext';

const StyledButton = styled(Button)<{ isFlat: boolean }>`
    align-self: flex-end;
    background: ${surfaceCard};
    flex: 1;
    max-width: 96px;

    ${({ isFlat }) => isFlat && 'height: 0px; margin-left: auto;'}
`;

type DownloadButtonProps = {
    data?: DownloadData;
};

export const DownloadButton: React.FC<DownloadButtonProps> = ({ data }) => {
    const tables = useAllTablesStateContext();
    const { statisticState } = useStatisticContext();

    const { showToast } = useToast();
    const [isLoading, setIsLoading] = React.useState(false);
    const pathname = usePathname() as Pathname;

    const handleError = React.useCallback(
        (text = errorText) => {
            showToast({ text, position: 'top', timeout: 2000 });
            setIsLoading(false);
            captureException(new Error('SSU_DOWNLOAD_FAILURE'));
        },
        [showToast],
    );

    const handleDownloadClick = React.useCallback(() => {
        setIsLoading(true);
        switch (pathname) {
            case '/': {
                downloadContent(tables.contents.tableState, () => setIsLoading(false), handleError);
                break;
            }
            case '/disciplines': {
                downloadDisciplines(tables.disciplines.tableState, () => setIsLoading(false), handleError);
                break;
            }
            case '/questions': {
                downloadQuestions(tables.questions.tableState, () => setIsLoading(false), handleError);
                break;
            }
            case '/statistics': {
                downloadStatistics(statisticState, () => setIsLoading(false), handleError, data);
                break;
            }
            default: {
                setIsLoading(false);
                break;
            }
        }
    }, [pathname, tables, data, statisticState, handleError]);

    return (
        <StyledButton isFlat={!!data} isLoading={isLoading} onClick={handleDownloadClick}>
            <IconDownload color={accent} />
        </StyledButton>
    );
};
