import { StrapiEntity } from '@/api/types/strapi';

export const mapToOptions = (relation: StrapiEntity<{ name: string } | { subdomain_name: string }>) => ({
    value: relation.id.toString(),
    label: 'name' in relation.attributes ? relation.attributes.name : relation.attributes.subdomain_name,
});

export const commonT = {
    domain_id: 'Домен',
    subdomain_id: 'Поддомен',
    disciplines_id: 'Дисциплина',
    divisions_id: 'Подразделение Сбера',
    status: 'Статус обработки',
    createdAt: 'Дата создания',
    updatedAt: 'Дата редактирования',
} as const;

export const nullText = '* нет данных *';

export const PAGE_SIZE = 10;

export const defaultQuery = `populate=*&pagination[pageSize]=${PAGE_SIZE}`;
