import React from 'react';
import { Select } from '@salutejs/plasma-web';

import { StyledLabel } from '../Forms/components/styled';

import { PropsType } from '@/types/PropsType';

type MultiSelectProps = Omit<PropsType<typeof Select>, 'value' | 'placeholder'> & {
    onChange: (value: string[]) => void;
    label?: string;
    value?: string[];
    defaultOptionLabel?: string;
    listHeight?: string;
};

const ALL_OPTION = 'defaultSelectValue';
const ALL_OPTION_LABEL = 'Все';

export const MultiSelect: React.FC<MultiSelectProps> = ({
    label,
    className,
    items,
    onChange,
    value,
    defaultOptionLabel = ALL_OPTION_LABEL,
    listHeight = '312px',
}) => {
    const itemsWithAllOption = React.useMemo(
        () => [{ label: defaultOptionLabel, value: ALL_OPTION }, ...items],
        [items, defaultOptionLabel],
    );

    // useCallback здесь ломает Select из плазмы, в нём не обновляется value
    // а так меняется onChange
    // ¯\_(ツ)_/¯
    const handleChange = (values: string[]) => {
        if (
            (values.length === 1 && values[0] === ALL_OPTION) ||
            values.length === 0 ||
            (values.length > 1 && values[values.length - 1] === ALL_OPTION)
        ) {
            onChange([]);

            return;
        }

        const newValues = values.filter((e) => e !== ALL_OPTION);

        onChange(newValues);
    };

    return (
        <StyledLabel className={className}>
            {label}
            <Select
                listHeight={listHeight}
                listOverflow="auto"
                value={value?.length ? value : [ALL_OPTION]}
                items={itemsWithAllOption}
                onChange={handleChange}
                multiselect
                placeholder=""
            />
        </StyledLabel>
    );
};
