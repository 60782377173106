import { commonT } from './common';

import { FieldWithMultiselect } from '@/components/Forms/types';
import { GetDisciplinesResponse } from '@/api/types/disciplines';

type Attributes = keyof GetDisciplinesResponse['data'][number]['attributes'] | 'domain_id' | 'block';

export type SubmittedFormData = Record<Attributes, string>;

const t: Record<Attributes, string> = {
    ...commonT,
    name: commonT.disciplines_id,
    block: 'Блок',
    study_period: 'Период обучения',
};

export const disciplineTableColumns: Attributes[] = [
    'name',
    'subdomain_id',
    'domain_id',
    'study_period',
    'divisions_id',
    'block',
    'createdAt',
    'updatedAt',
];

export const translateDisciplineAttribute = (attribute: Attributes) =>
    attribute in t ? t[attribute as Attributes] : attribute;

export const disciplineFormFields: FieldWithMultiselect<Attributes | 'domain_id'>[] = [
    {
        element: 'input',
        type: 'text',
        name: 'name',
        label: translateDisciplineAttribute('name'),
        placeholder: 'Введите название дисциплины',
    },
    {
        element: 'select',
        name: 'domain_id',
        label: translateDisciplineAttribute('domain_id'),
        placeholder: 'Выберите домен',
        getOptions: () => [],
    },
    {
        element: 'select',
        name: 'subdomain_id',
        parentName: 'domain_id',
        placeholder: 'Выберите (после выбора домена)',
        label: translateDisciplineAttribute('subdomain_id'),
        getOptions: () => [],
    },
    {
        element: 'multiselect',
        name: 'divisions_id',
        placeholder: 'Выберите подразделения Сбера (опционально)',
        label: translateDisciplineAttribute('divisions_id'),
        required: false,
        getOptions: () => [],
        defaultOptionLabel: 'Не указано',
    },
    {
        element: 'select',
        name: 'study_period',
        placeholder: 'Выберите',
        label: translateDisciplineAttribute('study_period'),
        required: false,
        getOptions: () => [],
    },
];
